import React, { useState } from "react";
import MaintenanceButtons from "./MaintenanceButtons";
import CustTaskDetails from "./CusTaskDetails";
import SiteInfo from "../../Site/SiteSingleViewComponents/SiteInfo";
import MaintenanceInfo from "./MaintenanceInfo";
import CommentList from "./CommentList";
import {
    Grid
} from "@material-ui/core";
import UploadFiles from "../../../Components/Modals/UploadFiles";
import { downloadFile } from "../../../Functions/fileDownload";
import SiteImageModal from "../../../Components/Modals/SiteImageModal";

const MaintenanceDetails = (props) => {
    const {
        classes,
        userPermissions,
        isAuthenticated,
        customer,
        data,
        tiny,
        isSm,
        language,
        site,
        newComment,
        setUploadModal,
        uploadModalStatus,
        setFile,
        userId,
        openActionModal,
        openAsignToMeModal,
        openActionCancelModal,
        openResetAlocationModal,
        sendingComment,
        commentText,
        handleCommentTextChange,
        showCommentBox,
        handleCommentBoxShow,
        openCloseMaintenanceWithImage
    } = props;

    return (
        <>
            <UploadFiles
                open={uploadModalStatus}
                close={() => setUploadModal(false)}
                execute={(base64File) => setFile(base64File)}
                language={props.language}
            />
            <CustTaskDetails
                customer={customer}
                language={language}
                tiny={tiny}
                userPermissions={userPermissions}
                isAuthenticated={isAuthenticated}
            />
            <SiteInfo
                data={site}
                language={language}
                userPermissions={userPermissions}
                isAuthenticated={isAuthenticated}
            />
            <SiteImageModal siteId={site.OBJID}/>
            <MaintenanceInfo
                type="MAINTENANCE"
                classes={classes}
                data={data.maintenance}
                language={language}
                tiny={tiny}
                isSm={isSm}
            />
            <Grid
                item
                xs={12}
                container
                direction="row"
                spacing={4}
                className={classes.buttonGroup}
            >
                <MaintenanceButtons
                    language={language}
                    tiny={tiny}
                    maintenance={data.maintenance}
                    userPermissions={userPermissions}
                    isAuthenticated={isAuthenticated}
                    userId={userId}
                    openAction={() => openActionModal()}
                    openAsignToMeModal={openAsignToMeModal}
                    openBilling={() => alert("setBillingModalStatus")}
                    openBillingModal={() => alert("setBillingModalStatus")}
                    openActionCancelModal={openActionCancelModal}
                    openResetAlocationModal={openResetAlocationModal}
                    openCloseMaintenanceWithImage={openCloseMaintenanceWithImage}
                />
            </Grid>
            <CommentList
                downloadFile={downloadFile}
                classes={classes}
                task={data}
                data={data && data.history}
                language={language}
                tiny={tiny}
                newComment={newComment}
                openFileUploadModal={() => setUploadModal(true)}
                sendingComment={sendingComment}
                commentText={commentText}
                showCommentBox={showCommentBox}
                handleCommentTextChange={handleCommentTextChange}
                handleCommentBoxShow={handleCommentBoxShow}
            />
        </>
    )
};

export default MaintenanceDetails;