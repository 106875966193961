import {
	OVERWRITE_PROJECT_OWNER,
	TECHNICAL_TEAM,
	VIEW_AND_EDIT_TECHNICAL_SCORE,
	VIEW_AND_EDIT_TECHNICAL_SCORE_HISTORY
} from "./permissions";

export const subPermissionDetails = {
	ADD_CUSTOMER: {
		ro: `Dă dreptul de creare a unui client pe pagina Clienților (Butonul "+"  din partea stânga sus a tabelului).`,
		en: `Give the permission to add a customer on the Customer page (The "+" button on the left side)`,
	},
	EDIT_CUSTOMER: {
		ro: `Acestă opțiune permite editarea detaliilor clienților pe pagina de client și validarea clienților pe pagina de afișare a cliențiilor.`,
		en: `Give the permission to edit the customer and validate the customer.`,
	},
	VIEW_CUSTOMER: {
		ro: `Acestă permisiune face ca pagina de clienții să fie vizibilă în meniul din partea stânga.`,
		en: `Make the client page to be visible in the drawer.`,
	},
	VIEW_CUSTOMER_DETAIL: {
		ro: `Dă dreptul de a intra pe pagina unui client de pe pagina de afișare a cliențiilor.`,
		en: `Give the permission to go to the client page.`,
	},
	ADD_TASK: {
		ro: `Permite adăugarea unui nou ticket de pe pagina obiectivului.`,
		en: `Give the permission to add a task from the site page.`,
	},
	EDIT_TASK: {
		ro: `Acestă permisiune se aplică pe pagina de tickete și dă posibiliatea de a modifica prioritatea ticketelor.`,
		en: `Give the permission to change the task priority.`,
	},
	EDIT_TASK_OWN: {
		ro: `Nu este implementată încă!`,
		en: `Not implement yet.`,
	},
	VIEW_TASK: {
		ro: `Acestă permisiune face ca pagina de tickete să fie vizibilă în meniul din partea stângă și pe pagina de acasă.`,
		en: `Make the Task page visible on the drawer and home page.`,
	},
	VIEW_TASK_DETAIL: {
		ro: `Dă dreptul de a intra pe un ticket.`,
		en: `Give the right to view a task.`,
	},
	ADD_CONTRACT: {
		ro: `Dă dreptul de a crea un nou contract pe pagina unui obiectiv.`,
		en: `Give the right to create a new contract.`,
	},
	VIEW_CONTRACT: {
		ro: `Dă dreptul de a vizualiza contractele pe pagina unui obiectiv.`,
		en: `Give the right to view the contracts on the site page.`,
	},
	VIEW_CONTRACT_DETAIL: {
		ro: `- Nu este implementată încă`,
		en: `- Not implement yet.`,
	},
	DASHBOARD: {
		ro: `Permisiunea acestă dă dreptul de acces pe pagina de administrare.`,
		en: `Give the right to access the administration page.`,
	},
	VIEW_SITE: {
		ro: `Permisiunea acestă oferă accesul către pagina unui obiectiv din următoarele locații: pagina client, pagina obiectivelor și meniu.`,
		en: `Give the access to the site page through: customer page, sites page and the drawer`,
	},
	EDIT_SITE: {
		ro: `Oferă accesul la editarea unui obiectiv de pe pagina de obiectiv și validarea obiectivelor de pe pagina de obiective.`,
		en: `Give the right to edit and validate a site.`,
	},
	ADD_SITE: {
		ro: `Oferă dreptul de a adăuga un obiectiv de pe pagina de client.`,
		en: `Give the right to add a new site on the customer page.`,
	},
	QUICK_SITE_SEARCH: {
		ro: `Face vizibilă scurtătura de căutare a obiectivelor pe pagina de acasă.`,
		en: `Make visible the search shortcut for sites on the home page.`,
	},
	QUICK_CUSTOMER_SEARCH: {
		ro: `Face vizibilă scurtătura de căutare a clienților pe pagina de acasă.`,
		en: `Make visible the search shortcut for customers on the home page/`,
	},
	QUICK_CUSTOMER_ADD: {
		ro: `Face vizibilă scurtătura de agăugare rapidă a clienților de pe pagina de acasă. (Acestă scurtătura a fost creată pentru adăugarea de către tehnicieni a unui client nou cu obiectiv și ticket)`,
		en: `Make visible the add quick cutomer shortcut on the home page.`,
	},
	EDIT_CONTRACT: {
		ro: `Face vizibil butonul de rezilier și editare contract de pe pagina obiectivului în dialogul de contracte active`,
		en: `Edit contract`,
	},
	TAKE_TASK: {
		ro: "Dă posibilitatea ca un utilizator să își poată aloca un ticket și dezaloca",
		en: "The user can take a task",
	},
	VIEW_REPORTS_PAGE: {
		ro: "Utilizatorul poate sa vadă și să acceseze pagina de rapoarte",
		en: "The user can acces the reports page",
	},
	GENERATE_ACTIVITY_REPORT: {
		ro:
			"Utilizatorul poate să genereze Raportul de Activități Desfășurate #1",
		en: "The user can generate the activity report #1",
	},
	GENERATE_ACTIVITY_REPORT_2: {
		ro:
			"Utilizatorul poate să genereze Raportul de Activități Desfășurate #2",
		en: "The user can generate the activity report #2",
	},
	CANCEL_TASK: {
		ro: "Utilizatorul v-a putea anula orice ticket",
		en: "The user can cancel task",
	},
	VIEW_TASK_HISTORY: {
		ro:
			"Utilizatorul v-a putea vizualiza tabelul cu istoricul ticketelor",
		en: "The user will be able to see the task history table",
	},
	BILL_VALIDATION: {
		en: "Bill validation",
		ro: "Utilizatorul v-a putea valida facturarea.",
	},
	RECEIPT_VALIDATION: {
		en: "Receipt validation",
		ro: "Utilizatorul v-a putea valida încasarea.",
	},
	SITE_ADD_SYSTEM_LOGIN_INFO: {
		en:
			"The user can view and add the login information for the site system",
		ro:
			"Utilizatorul poate să vizualizeze și să adauge informațiile de logare pentru sistemul obiectivului",
	},
	VIEW_SYSTEM_LOGIN_INFO: {
		en: "The user can see the system login info",
		ro: "Utilizatorul poate vizualiza informațiile de logare la sistem",
	},
	VIEW_SITE_AREA: {
		en: "The user can see the site area on the site page",
		ro:
			"Utilizatorul poate vizualiza zonele de alarmă ale obiectivului de pe pagina obiectivului",
	},
	EDIT_SITE_AREA: {
		en: "The user can edit a existing site area from the site page",
		ro:
			"Utilizatorul v-a putea edita o zonă de alarmă existentă pe de pagina obiectivului",
	},
	ADD_SITE_AREA: {
		en: "The user can add a new site are from the site page",
		ro: "Utilizatorul v-a putea sa adauge o nouă zonă de alarmă ",
	},
	TASK_CHANGE_PRIORITY: {
		en: "The user can change the priority of any task",
		ro: "Utilizatorul poate modifica prioritate oricarui deranjamnet activ",
	},
	SITE_VIEW_CENTRAL_INFO: {
		en: "The user can see the central information",
		ro: "Utilizatorul poate vizualiza inforamțiile centralei",
	},
	SITE_VIEW_CONTRACT_TEHNIC: {
		en: "View the contract with the tehnic type",
		ro: "Poate vizualiza contractele de tip tehnic",
	},
	SITE_VIEW_CONTRACT_WATCH: {
		en: "View the contract with the watch type",
		ro: "Poate vizualiza contractele de pază",
	},
	ACTION_INTERVENTION_BASIC: {
		en: "The user can only do basic actions regarding interventions (excluding actions like closing or validating the issue)",
		ro: "Utilizatorul va putea face acțiuni simple pe alarme (nu va putea închide, anula sau valida alarmele)",
	},
	ACTION_INTERVENTION_DISPATCHER: {
		en: "The user can only close or validate the intervention",
		ro: "Utilizatorul va putea închide, anula și valida alarme",
	},
	ACCOUNTING_ACCESS: {
		en: "The user can access the section regarding accounting.",
		ro: "Utilizatorul va putea accesa secțiunea cu privire la contabilitate.",
	},
	ADD_INTERVENTION: {
		en: "The user can add a new intervention.",
		ro: "Utilizatorul va putea adăuga o nouă alarmă.",
	},
	VIEW_PHONE_WARNING: {
		en: "The user can view all the phone warnings",
		ro: "Utilizatorul va putea vedea toate înştiinţările telefonice"
	},
	ADD_PHONE_WARNING: {
		en: "The user can add phone warnings",
		ro: "Utilizatorul va putea adăuga înştiinţări telefonice"
	},
	VIEW_INTERVENTION_HISTORY: {
		en: "The user cna see the intervetion history",
		ro: "Vizualizare istoric alarme"
	},
	VIEW_CUSTOMER_INTERVENTION: {
		en: "The user can see the customer intervention",
		ro: "Utilizatorul va putea să vadă alarmele clientului (pe pagina clientului)"
	},
	VIEW_SITE_INTERVENTION: {
		en: "The user can see the site intervention",
		ro: "Utilizatorul va putea să vadă alarmele pe fiecare obiectiv de pe pagina unui client"
	},
	EDIT_USER: {
		en: "The user can edit other users",
		ro: "Utilizatorul va putea să editeze alți utilizatori"
	},
	CHANGE_CUSTOMER_STATUS: {
		en: "The user can change the state of the customer in inactiv/activ",
		ro: "Utilizatorul va putea să schimbe statusul clienților în activ/inactiv"
	},
	QUICK_INTERVENTION: {
		en: "The user will see the INTERVENTION shortcut on the home page",
		ro: "Utilizatorul va putea să vadă scurtatura pentru alarme"
	},
	VIEW_TEMPLATE_FILE: {
		en: "The user can see and download the template files",
		ro: "Utilizatorul va putea să vadă și ă descarce fișierele tipizate"
	},
	DELETE_TEMPLATE_FILE: {
		en: "The user cand delete a template files",
		ro: "Utilizatorul va putea să șteargă fișere tipizate"
	},
	ADD_TEMPLATE_FILE: {
		en: "The user can add a template file",
		ro: "Utilizatorul va putea să adauge un fișier tipizat"
	},
	VALIDATE_TASK_BILL_RECIPE: {
		en: "The user can validate a task bill recipe",
		ro: "Utilizatorul va putea să valideze facturarea ticketelor"
	},
	VALIDATE_TASK_BILL_CHASHING: {
		en: "The user can validate a task bill chasing",
		ro: "Utilizatoul va putea să valideze încasare facturilor pentru tickete"
	},
	QUICK_ACTION_VIEW_TASK_BILL_HISTORY: {
		en: "The user can access the task bill history from the quick action",
		ro: "Utilizatorul va putea să vadă scurtătura pentru istoricul de facturi/încasări al ticketelor"
	},
	QUICK_ACTION_VIEW_TEMPLATE_FILES: {
		en: "The user can see the template file shortcut",
		ro: "Utilizatorul va putea să vadă scurtătura pentru fișere tipizate"
	},
	VIEW_TASK_BILL_HISTORY: {
		en: "The user can see the task bill history",
		ro: "Utilizatorul are acess la istoricul de facturi/încasări pentru tickete"
	},
	UPDATE_TASK_REASON: {
		en: "The user can make a update for the task reason",
		ro: "Uilizatorul va putea sa updateze motivul ticketului"
	},
	EDIT_TASK_BILL: {
		en: "The user can edit the bill after validation",
		ro: "Utilizatorul va putea edita factura după validare(pe pagina obiectivului)"
	},
	SUPER_RESET_TASK: {
		en: "The user can reset any task",
		ro: "Utilizatorul va putea sa anuleze alocarea oricărui ticket"
	},
	ADD_MAINTENANCE: {
		en: "The user can add a miantenace",
		ro: "Utilizatorul va putea sa adauge mentenanțe manual"
	},
	CANCEL_MAINTENANCE: {
		en: "The user can cancel a maintenance",
		ro: "Utilizatoul va pute anula o mentenață"
	},
	TAKE_MAINTENANCE: {
		en: "The user take a maintenace",
		ro: "Utilizatorul va putea prelua o mentenață"
	},
	VIEW_MAINTENANCE_HISTORY: {
		en: "The user can see the maintenance history",
		ro: "Utilizatorul va putea sa vadă istoricul de mentenanțe"
	},
	RESET_MAINTENANCE: {
		en: "The user can reset the maintenance",
		ro: "Utilizatoul va putea anula alocarea unei mentenanțe"
	},
	QUICK_VIEW_MAINTENANCE_SHORTCUT: {
		en: "The user can view the home shortcut for maintenance",
		ro: "Utilizatoul va vedea scurtătura de pe home page"
	},
	VIEW_MAINTENANCE: {
		en: "The user can view the maintenances",
		ro: "Utilizatorul va putea să vadă și să acceseze mentenanțele"
	},
	SUPER_DELETE: {
		en: "This permission give rights to delete cutomers, sites and contracts",
		ro: "Utilizatorul va putea să șteargă obiectiv, clienți și contracte."
	},
	TECHNIC_MANAGEMENT: {
		en: "The user has access to the tehnic management panel",
		ro: "Utilizatorul are acess la panoul de control pentru activitate de tehnic"
	},
	VIEW_INTERVENTION_WITH_PAY: {
		en: "The user can see if a intervention is includet in the contract free truck rolls",
		ro: "Utilizatorul poate sa vada statusul unei interventii (plata sau inclusa in abonament)"
	},
	AREA_MANAGMENT: {
		en: "The user can see the area management",
		ro: "Utilizatorul poate sa accesez Gestiuna zonelor"
	},
	VIEW_PROJECTS: {
		en: "-",
		ro: "Vizualizare proiecte"
	},
	ADD_PROJECTS: {
		en: "Add project",
		ro: "Adaugare proiecte"
	},
	VIEW_RISC_EVALUATIONS: {
		en: "View risc evalautiuons",
		ro: "Vizualizare evaluari de risc"
	},
	ADD_RISC_EVALUATIONS: {
		en: "Add risc evalautions",
		ro: "Adaugare evaluari de risc"
	},
	QUICK_VIEW_RISC_EVALUATION: {
		en: "Quick view risc evaluation",
		ro: "Utilizatorul va vedea scurtatura pentru accesare rapida a evaluarilor de risc"
	},
	QUICK_PROJECTS_VIEW: {
		en: "Quick project view",
		ro: "Utilizatorul va vedea scurtatura pentru accesare rapida a proiectelor"
	},
	QUICK_INSTALLATION_VIEW: {
		en: "Quick installation view",
		ro: "Scurtatura instalare"
	},
	ADD_INSTALLATION: {
		en: "Add installation",
		ro: "Adauga installare"
	},
	VIEW_INSTALLATION_HISTORY: {
		en: "View installation history",
		ro: "Utiliatorul va putea sa vada istoricul general de instalari."
	},
	VIEW_INSTALLATION: {
		en: "The user can view the installations",
		ro: "Utilizatorul va putea să vadă și să acceseze instalările"
	},
	VIEW_SCORE: {
		en: "The user can view installations scores",
		ro: "Utilizatorul va putea să vadă punctajele instalărilor"
	},
	VALIDATE_SCORE: {
		en: "The user can validate the installation scores",
		ro: "Utilizatorul va putea să valideze punctajele instalărilor"
	},
	OVERWRITE_SCORE: {
		en: "The user can overwrite the installation scores",
		ro: "Utilizatorul va putea să suprascrie punctajele instalărilor"
	},
	VALIDATE_INSTALLATION_BILL_RECIPE: {
		en: "Validate installation bill recipe",
		ro: "Utilizatorul va putea sa valideze facturile de instalare"
	},
	VALIDATE_INSTALLATION_BILL_CHASING: {
		en: "Validate installation bill chasing",
		ro: "Utilizatorul va putea sa valideze incasarile pe facturile de instalare"
	},
	TAKE_INSTALLATION: {
		en: "Take installation",
		ro: "Preluare Instalare"
	},
	CANCEL_INSTALLATION: {
		en: "Cancel installation",
		ro: "Anulare instalare"
	},
	RESET_INSTALLATION: {
		en: "Reset installation",
		ro: "Resetare alocare instalare"
	},
	CASHING_VALIDATION_OVERWRITE: {
		en: "-",
		ro: "Permite suprascrierea valori facturi in cadrul validari de incasare"
	},
	CANCEL_PROJECT: {
		en: "-",
		ro: "Permite utilizatorului sa anuleze un ticket de proiectare"
	},
	OVERWRITE_PROJECT_OWNER: {
		en: "-",
		ro: "Permite utilizatorului sa preia un ticket de proiectare de la alt user!"
	},
	VIEW_AND_EDIT_TECHNICAL_SCORE: {
		en: "-",
		ro: "Vizualizare și editare punctaje utilizatori"
	},
	VIEW_AND_EDIT_TECHNICAL_SCORE_HISTORY: {
		en: "-",
		ro: "Vizualizare și editare istoric punctaje utilizatori"
	}
};

export const permissionDetails = {
	CUSTOMERS: {
		ro: `Această categorie de permisiune este legată de clienții!`,
		en: `Customer permission.`,
	},
	TASKS: {
		ro: `Această categorie de permisiunii este legată de ticket!`,
		en: `Task paermission.`,
	},
	CONTRACTS: {
		ro: `Acestă categorie de permisiunii este legată de contracte!`,
		en: `Contracts permission.`,
	},
	SITE: {
		ro: `Acestă categorie de permisiunii este legată de obiective!`,
		en: `Site permission.`,
	},
	QUICK_ACTION: {
		ro: `Acestă categorie de permisiunii este legată de acțiunile rapide de pe pagina de acasă!`,
		en: `Quick action permisssion.`,
	},
	DASHBOARD: {
		ro: `Acestă categorie de permisiunii este legată de administrare!`,
		en: `Dashboard permission.`,
	},
	REPORTS: {
		ro: "Acestă categorie de permisiunii este legată de rapoarte!",
		en: "Reports permission.",
	},
	BILLING: {
		en: "Billing permission.",
		ro: "Permisiunile pentru facturare",
	},
	INTERVENTIONS: {
		en: "Interventions permission.",
		ro: "Acestă categorie de permisiunii este legată de alarme.",
	},
	ACCOUNTING: {
		en: "Accounting permission.",
		ro: "Acestă categorie de permisiunii este legată de contabilitate.",
	},
	USERS: {
		en: "User management.",
		ro: "Acestă categorie de permisiunii este legată de managerierea utilizatorilor.",
	},
	TEMPLATE_FILE: {
		en: "Template files",
		ro: "Acestă categorie are legătură cu fișierele tipizate"
	},
	MAINTENANCE: {
		en: "Maintenance permission",
		ro: "Permisiunii mentenanțe"
	},
	SUPER: {
		en: "Super permission",
		ro: "Aceste permisiunii necesită un nivel înalt de atenție pentru a fi utilizate"
	},
	PROJECTS: {
		en: "Projects",
		ro: "Proiectare"
	},
	RISC_EVALUATIONS: {
		en: "Risc evaluations",
		ro: "Evaluari de risc"
	},
	INSTALLATION: {
		en: "Instalaltion",
		ro: "Installare"
	},
	TECHNICAL_TEAM: {
		ro: "Punctaje utilizatori",
		en: "Technical team score"
	}
};
