import axios from "axios";
import { useEffect, useState } from "react";
import {Grid, TextField, Chip, makeStyles, Divider, Typography} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FaceIcon from "@material-ui/icons/Face";

import { getGetHeader, getPostHeader } from "../../../Constants/requestHeaders";
import * as lang from "../../../Constants/strings";
import CustomModal from "../../../Components/Modals/CustomModal";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreator from "../../../actions/actions";

const useStyle = makeStyles((theme) => ({
  autocomplete: {
    margin: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(2)
  },
  typo: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const AddTechnicalTeamModal = (props) => {
  const {
    language,
    userData
  } = props;

  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [carNumber, setCarNumber] = useState('');

  const getUsers = async () => {
    try {
      const res = await axios.get('/api/team_users', getGetHeader());
      // Remove the current user form the list
      const tmp = [...res.data];
      const index = tmp.findIndex((e) => e.USER_ID === userData.id);
      if (index !== -1) tmp.splice(index, 1);
      setUsers(tmp);
    } catch (e) {
      setUsers([]);
    }
  };

  const getTeamsNames = async () => {
    try {
      const res = await axios.get('/api/teams', getGetHeader());
      setTeams(res.data);
    } catch (e) {
      setTeams([]);
    }
  };

  const checkIfUserHasTeam = async () => {
    try {
      const res = await axios.get('/api/user_team', getGetHeader());
      if(res.data.isTechnicalUser) {
        setOpen(!res.data.hasTeam);
      } else {
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const close = () => setOpen(false);

  const createTeam = async () => {
    try {
      const userIds = [userData.id];
      for (const u of selectedUsers) {
        userIds.push(u.USER_ID)
      }
      await axios.post('/api/teams',
        {
          name: carNumber,
          userIds,
        },
        getPostHeader()
      );
    } catch (e) {
      console.log(e);
    } finally {
      close();
    }
  }

  const deleteSelectedUser = async (index) => {
      const tmp = [...selectedUsers];
      tmp.splice(index, 1);
      setSelectedUsers(tmp);
  };

  useEffect(() => {
    checkIfUserHasTeam();
    getUsers();
    getTeamsNames();
  }, []);

  return (
    <CustomModal
      open={open}
      close={() => alert(lang.CAN_NOT_CLOSE_SELECTING_TEAM_MODAL[language])}
      fullScreen={false}
      title={lang.ADD_TECHNICAL_TEAM[language]}
      content={
        <Grid container spacing={2} justifyCenter="center" style={{ width: '100%', maxWidth: "400px" }}>
          <Autocomplete
            id="combo-box-teams"
            options={teams}
            className={classes.autocomplete}
            onInputChange={(t, v) => {
              if(v) {
                setCarNumber(v);
              } else if(t.target && t.target.value) {
                setCarNumber(t.target.value);
              } else {
                setCarNumber('');
              }
            }}
            inputValue={carNumber}
            freeSolo
            getOptionLabel={(option) => option}
            fullWidth
            renderInput={
              (params) =>
                <TextField
                  {...params}
                  value={carNumber}
                  label={lang.CAR_NUMBER[language]}
                  variant="outlined"
                />
            }
          />
          <Autocomplete
            id="combo-box-users"
            options={users}
            className={classes.autocomplete}
            getOptionLabel={(option) => option.USERNAME}
            onChange={(t, v) => {
              if(v)
                setSelectedUsers([...new Set([...selectedUsers, v])]);
            }}
            fullWidth
            renderInput={
            (params) =>
              <TextField
                {...params}
                label={lang.USERS[language]}

                variant="outlined"
              />
          }
          />
          <Grid xs={12}>
            <Divider fullWidth />
            <Typography variant="h6" className={classes.typo}>
              {lang.TEAM_MEMBERS[language]}
            </Typography>
          </Grid>
          <Chip
            key={`current_user`}
            className={classes.chip}
            icon={<FaceIcon />}
            label={userData ? userData.username: ""}
            color="primary"
          />
          {
            selectedUsers.map((u, i) => (
              <Chip
                key={`selected_user_${i}`}
                className={classes.chip}
                icon={<FaceIcon />}
                label={u.USERNAME}
                onDelete={() => deleteSelectedUser(i)}
                color="secondary"
              />
            ))
          }

        </Grid>
      }
      language={language}
      validate={carNumber && carNumber.length > 0}
      execute={() => createTeam()}
    />
  )
 };

const mapStateToProps = (state) => {
  return {
    language: state.config.language,
    theme: state.config.theme,
    isAuthenticated: state.config.isAuthenticated,
    permissions: state.auth.permissions,
    userData: state.auth.userData,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTechnicalTeamModal);