import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import qs from "qs";
import {
  makeStyles,
  CssBaseline,
  useTheme,
  Grid,
  useMediaQuery,
  Select,
  MenuItem,
  Typography,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import {
  VisibilityRounded,
  PriorityHigh,
  History,
  NewReleases,
  Receipt,
  Euro,
} from "@material-ui/icons";

import * as actionCreators from "../../actions/actions";
import * as lang from "../../Constants/strings";
import * as requestHeaders from "../../Constants/requestHeaders"

import MaterialTableSimple from "../../Components/Misc/Tables/MaterialTableSimple";
import TableSkeleton from "../Nomenclature/Skeleton";
import CustomButtonList from "../../Components/Modals/CustomButtonList";
import CustomModal from "../../Components/Modals/CustomModal";
import TaskModal from "../Site/SiteSingleViewComponents/Modals/TaskModal";
import CustomDrawer from "../../Components/Modals/CustomDrawer";
import TaskHistory from "./TaskHistory";
import TaskBillValidation from "./Billing/TaskBillValidation";
import TaskBillCashingValidation from "./Billing/TaskBillCashingValidation";

import { formatDateFromDb } from "../../Functions/dateFunctions";
import {
  tasks_view,
  small_tasks_view,
} from "../../Components/Misc/Tables/TableHeaders";
import { downloadFile } from "../../Functions/fileDownload";

import Can from "../../Components/Misc/Can";
import { checkPermissions } from "../../Functions/permissionHide";
import AddTechnicalTeam from "../TechnicalTeams/Modals/AddTechnicalTeam";
import RequestGPS from "../../Components/RequestGPS";
import GPSGuard from "../../Components/GPSGuard";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(10),
    width: "auto",
  },
  shortcut: {
    marginBottom: theme.spacing(10),
    margin: theme.spacing(1),
    transform: "rotate(180deg)",
  },
  margin: {
    margin: theme.spacing(2),
    width: "auto",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  areaSelect: {
    maxHeight: "50vh",
  }
}));

function TasksSetup(props) {
  const classes = useStyles();
  const {
    language,
    rowsPerPage,
    data,
    fetchData
  } = props;
  const theme = useTheme();
  const tiny = useMediaQuery(theme.breakpoints.down("xs"));

  const [selectedArea, setSelectedArea] = useState([]);
  const [areaList, setAreaList] = React.useState([]);

  const getAreaList = async () => {
    const response = await axios.get(`/api/areas/TASK`, requestHeaders.getGetHeader());

    if (response.status === 200) {
      setAreaList(response.data);
    } else {
      setAreaList([]);
    }
  };

  const handleAreaSelect = (newValue) => {
    if (newValue.props.value === 0) {
      setSelectedArea([]);
    } else {
      setSelectedArea([...selectedArea, newValue.props.value])
    }
  }

  useEffect(() => {
    getAreaList();
  }, []);

  useEffect(() => {
    let list = selectedArea.map(e => e.AREA)
    fetchData(list);
  }, [selectedArea]);

  return (
    <Grid container component="main" className={classes.margin}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AddTechnicalTeam />
          <Paper style={{ position: "relative" }}>
            <Select
              multiple
              select
              fullWidth
              style={{ borderRadius: "0px" }}
              variant="outlined"
              label={lang.AREA[language]}
              value={selectedArea}
              MenuProps={{
                className: classes.areaSelect,
                getContentAnchorEl: () => null
              }}
              onChange={(e, newValue) => handleAreaSelect(newValue)}
            >
              <MenuItem value={0}>NICIUNA</MenuItem>
              {areaList.sort((a, b) => a.AREA.toUpperCase() < b.AREA.toUpperCase() ? -1 : a.AREA.toUpperCase() > b.AREA.toUpperCase() ? 1 : 0).map(area => {
                return <MenuItem key={area.AREA} value={area}>
                  {area.AREA + ` (${area.TASK_COUNT})`}
                </MenuItem>
              })}
            </Select>
            <Typography variant="h6" style={{ position: "absolute", right: "45px", top: "12px" }} color="secondary">
              Nr. tickete {data.length}
            </Typography>
          </Paper>
        </Grid>
        <MaterialTableSimple
          tiny={tiny}
          title={lang.TASK[language]}
          language={language}
          data={data}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={props.onRowsPerPageChange}
          header={
            tiny === true ? small_tasks_view(language) : tasks_view(language)
          }
          dense={false}
          openAddModal={props.openAddModal}
          openRowModal={(id) => props.openRowModal(id)}
          clickLabel="TASK_ID"
          hideAddBtn={true}
        />
      </Grid>
    </Grid>
  );
}

function TaskSettingsModal(props) {
  const [priorityModalState, setPriorityModalState] = React.useState(false);
  const [priority, setPriority] = React.useState(2);
  const { language, close, selectedIndex, open, updatePriority } = props;
  const theme = useTheme();
  const tiny = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  return (
    <React.Fragment>
      <CustomDrawer
        open={open && tiny}
        close={close}
        list={[
          {
            primary: lang.GO_TO(lang.TASK[language])[language],
            secondary: "",
            icon: <VisibilityRounded />,
            action: () => props.setTaskModal(),
            render: props.permissionsCheck[0],
          },
          {
            primary: lang.SET_PRIORITY[language],
            secondary: "",
            icon: <PriorityHigh />,
            action: () => {
              setPriorityModalState(true);
            },
            render: props.permissionsCheck[1],
          },
        ]}
      />
      <CustomModal
        open={open && !tiny}
        title={lang.TASKS[language]}
        content={
          <Grid container spacing={2}>
            <CustomButtonList
              close={close}
              language={language}
              tiny={tiny}
              list={[
                {
                  text: lang.GO_TO(lang.TASK[language])[language],
                  style: {
                    icon: <VisibilityRounded className={classes.leftIcon} />,
                    color: "primary",
                    variant: "contained",
                  },
                  action: () => props.setTaskModal(),
                  render: props.permissionsCheck[0],
                },
                {
                  text: lang.SET_PRIORITY[language],
                  style: {
                    icon: <PriorityHigh className={classes.leftIcon} />,
                    color: "primary",
                    variant: "contained",
                  },
                  action: () => {
                    setPriorityModalState(true);
                  },
                  render: props.permissionsCheck[1],
                },
              ]}
              withBack
            />
          </Grid>
        }
        close={close}
        execute={close}
        language={language}
        noButtons
      />
      <Can neededPermissions={{ parent: "TASKS", name: "EDIT_TASK" }}>
        <CustomModal
          open={priorityModalState}
          title={lang.PRIORITY[language]}
          content={
            <Grid
              container
              alignItems="center"
              alignContent="center"
              direction="column"
              spacing={2}
            >
              <Typography variant="h6">
                {lang.SELECT_PRIORITY[language]}
              </Typography>
              <Select
                style={{ marginLeft: 10 }}
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              >
                <MenuItem value={1}>{lang.URGENT[language]}</MenuItem>
                <MenuItem value={2}>{lang.NORMAL[language]}</MenuItem>
              </Select>
            </Grid>
          }
          execute={() => {
            updatePriority(priority, selectedIndex) &&
              setPriorityModalState(false);
          }}
          language={language}
          close={() => setPriorityModalState(false)}
          validate
        />
      </Can>
    </React.Fragment>
  );
}

function TabSelector(props) {
  const { value, handleChange, language } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={(e, newValue) => {
            handleChange(newValue);
          }}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab
            icon={<NewReleases color="secondary" />}
            label={lang.ACTIVE_TASK[language]}
            value={1}
          />
          <Tab
            icon={<History color="primary" />}
            label={lang.TASK_HISTORY[language]}
            value={2}
            disabled={
              !(
                props.isAuthenticated &&
                checkPermissions(props.userPermissions, {
                  parent: "TASKS",
                  name: "VIEW_TASK_HISTORY",
                })
              )
            }
          />
          <Tab
            icon={<Receipt color="primary" />}
            label={lang.BILL_VALIDATION_WITH_SCORE_UPDATE[language]}
            value={3}
            disabled={
              !(
                props.isAuthenticated &&
                checkPermissions(props.userPermissions, {
                  parent: "TASKS",
                  name: "VALIDATE_TASK_BILL_RECIPE",
                })
              )
            }
          />
          <Tab
            icon={<Euro color="primary" />}
            label={lang.PAY_VALIDATION[language]}
            value={4}
            disabled={
              !(
                props.isAuthenticated &&
                checkPermissions(props.userPermissions, {
                  parent: "TASKS",
                  name: "VALIDATE_TASK_BILL_CHASHING",
                })
              )
            }
          />
        </Tabs>
      </Paper>
    </React.Fragment>
  );
}
class TaskView extends Component {
  state = {
    searchTerm: "",
    selectedIndex: null,
    settingModal: false,
    taskModalOpen: false,
    selectedTab: 1,
    trainees: [
      {
        NAME: "",
        SIGN: "",
      },
    ],

    project: {
      NAME: "",
      NUMBER: "",
      SIGN: "",
    },
    showProject: false,
    journal: {
      NAME: "",
      NUMBER: "",
      SIGN: "",
    },
    journalData: {
      NO: "",
      DATE: new Date(),
      POS: "",
    },
    opt: [false, false, false, false],
    showJournal: false,
    permissionsCheck: [],
  };

  componentDidMount() {
    document.title = "RPG - " + lang.TASK[this.props.language];
    this.props.actions.checkSession();
    this.props.actions.setSortColumn1("OBJID");
    this.props.actions.setSortItem(null);
    this.props.actions.setCurrentPage(0);
    this.getActiveTaskList(
      this.props.selectedSortItem
    );
    this.props.actions.countActiveTasks();
    const permissions = [
      checkPermissions(this.props.permissions, {
        parent: "TASKS",
        name: "VIEW_TASK_DETAIL",
      }) && this.props.isAuthenticated,
      checkPermissions(this.props.permissions, {
        parent: "TASKS",
        name: "TASK_CHANGE_PRIORITY",
      }) && this.props.isAuthenticated,
    ];
    this.setState({ permissionsCheck: permissions });
  }

  componentWillUnmount() {
    this.props.actions.resetSortItem(null);
  }

  async updatePriority(priority, id) {
    await this.props.actions.setTaskPriority(priority, id);
    await this.getActiveTaskList();
  }

  updateProject = (field, value) => {
    if (field === "SHOW")
      this.setState({ showProject: !this.state.showProject });
    else {
      let placeholder = this.state.project;
      placeholder[field] = value;
      this.setState({ project: placeholder });
    }
  };
  updateJournal = (field, value) => {
    if (field === "SHOW")
      this.setState({ showJournal: !this.state.showJournal });
    else {
      let placeholder = this.state.journal;
      placeholder[field] = value;
      this.setState({ journal: placeholder });
    }
  };
  resetProject = () => {
    this.setState({
      project: { NAME: "", NUMBER: "", SIGN: "" },
      showProject: false,
    });
  };
  resetJournal = () => {
    this.setState({
      journal: { NAME: "", NUMBER: "", SIGN: "" },
      showJournal: false,
    });
  };
  addRemoveTrainee = (index) => {
    let placeholder = this.state.trainees;
    if (index === false) placeholder.push({ NAME: "", SIGN: "" });
    else placeholder.splice(index, 1);
    this.setState({ trainees: placeholder });
  };
  updateTrainee = (index, field, value) => {
    let placeholder = this.state.trainees;
    placeholder[index][field] = value;
    this.setState({ trainees: placeholder }, console.log(this.state.trainees));
  };
  resetTrainees = () => {
    this.setState({ trainees: [{ NAME: "", SIGN: "" }] });
  };
  updateOpt = (index) => {
    let placeholder = this.state.opt;
    placeholder[index] = !placeholder[index];
    this.setState({ opt: placeholder }, console.log(this.state.opt));
  };
  resetOpt = () => {
    this.setState({ opt: [false, false, false, false] });
  };
  updateJournalData = (field, value) => {
    let placeholder = this.state.journalData;
    placeholder[field] = value;
    this.setState({ journalData: placeholder });
  };
  resetJournalData = () => {
    this.setState({
      journalData: {
        NO: "",
        DATE: new Date(),
        POS: "",
      },
    });
  };

  async getActiveTaskList(area) {

    const response = await axios.get('/api/get_active_task_list', {
      ...requestHeaders.getGetHeader(),
      params: {
        area
      },
      paramsSerializer: params => qs.stringify(params)
    });

    if (response.status === 200 | response.status === 201) {
      this.props.actions.onTaskListRecieve(response.data.data);
    } else {
      this.props.actions.onTaskListRecieve([]);
      this.props.actions.onNotificationOpen(
        "warning",
        lang[response.data.message],
        6000
      );
    }

    this.props.actions.SignInUser(false, false, false, true);
  };

  newTaskAction = async (
    changeType,
    changeFrom,
    changeTo,
    note,
    siteContact,
    signature,
    serviceNo,
    warranty,
    opt,
    trainees,
    project,
    journal,
    dispatcher,
    type,
    products,
    teamSize,
    deadLine,
    pvImage
  ) => {
    this.props.actions.newTaskAction(
        this.props.userAuth.id,
        {
          TASK_OBJID: this.props.taskData.task.OBJID,
          REASON: this.props.taskData.task.REASON,
          CHG_USER: this.props.userAuth.id,
          CHG_TYPE: changeType,
          CHG_FROM: changeFrom,
          CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
          NOTE: note,
          CONTRACT_NO: this.props.taskData.task.CONTRACT_NO,
          CONTRACT_TYPE: this.props.taskData.task.CONTRACT_TYPE
            ? lang[this.props.taskData.task.CONTRACT_TYPE][this.props.language]
            : "",
          SITE_CONTACT: siteContact,
          SIGNATURE: signature,
          SERVICE_NO: serviceNo,
          WARRANTY: warranty,
          OPT: opt,
          TRAINEES: trainees,
          PROJECT: project,
          JOURNAL: journal,
          DISPATCHER_ID: dispatcher,
          TYPE: type,
          PRODUCTS: products,
          TEAM_SIZE: teamSize,
          JOURNAL_DATA: this.state.journalData,
          DEAD_LINE: deadLine
            ? formatDateFromDb(deadLine, 12)
            : formatDateFromDb(new Date(), 12),
          PV_IMAGE: pvImage,
          GPS: this.props.geolocation,
        }
    );

    await this.props.actions.getActiveTaskData(this.state.selectedIndex);
    if (this.props.taskData?.task?.CONTACT_OBJID) {
      await this.props.actions.getCustomerData(
        this.props.taskData.task.CONTACT_OBJID
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <GPSGuard />
        <CssBaseline />
        <TaskModal
          siteData={this.props.siteData}
          language={this.props.language}
          open={this.state.taskModalOpen}
          close={() => {
            this.setState({ taskModalOpen: false });
          }}
          taskId={this.state.selectedIndex}
          user={this.props.userAuth}
          userPermissions={this.props.permissions}
          isAuthenticated={this.props.isAuthenticated}
          onNotificationOpen={(type, message) =>
            this.props.actions.onNotificationOpen(type, lang[message], 6000)
          }
          addCustomerContract={(data) => {
            this.props.actions.addCustomerContract(
              this.props.userAuth.id,
              {
                ...data,
                SITE_OBJID: this.props.siteData.OBJID,
                SALE_CONTACT: this.props.userAuth.id,
              },
              this.props.language
            );
          }}
          isUploadingFile={this.props.isUploadingFile}
          cust={{
            data: this.props.customerData,
            loading:
              this.props.isFetchingCustomerData ||
              this.props.isFetchingSiteData,
            taskData: this.props.taskData,
            initialLoad: this.props.taskData.task
              ? () =>
                this.props.actions.getCustomerData(
                  this.props.taskData.task.CONTACT_OBJID
                )
              : () => console.log("No customer id"),
          }}
          task={{
            opt: this.state.opt,
            updateOpt: this.updateOpt,
            resetOpt: this.resetOpt,
            project: this.state.project,
            journal: this.state.journal,
            showProject: this.state.showProject,
            showJournal: this.state.showJournal,
            updateProject: this.updateProject,
            updateJournal: this.updateJournal,
            journalData: this.state.journalData,
            updateJournalData: this.updateJournalData,
            resetJournalData: this.resetJournalData,
            resetProject: this.resetProject,
            resetJournal: this.resetJournal,
            trainees: this.state.trainees,
            updateTrainee: this.updateTrainee,
            addRemoveTrainee: this.addRemoveTrainee,
            resetTrainees: this.resetTrainees,
            userId: this.props.userAuth.id,
            data: this.props.taskData,
            loading: this.props.isFetchingTaskData,
            downloadFile: downloadFile,
            fetchData: async () => {
              await this.props.actions.getTaskData(this.state.selectedIndex);
              await this.props.actions.getSiteData(this.props.taskData.task.SITE_OBJID);
            },
            newTaskAction: this.newTaskAction,
            resetTaskAction: (changeType, changeFrom, changeTo, note) =>
              this.props.actions.resetTaskAction(this.props.userAuth.id, {
                TASK_OBJID: this.props.taskData.task.OBJID,
                REASON: this.props.taskData.task.REASON,
                CHG_USER: this.props.userAuth.id,
                CHG_TYPE: changeType,
                CHG_FROM: changeFrom,
                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                NOTE: note,
                GPS: this.props.geolocation,
              }),
            newTaskComment: (changeType, changeFrom, changeTo, note, file) =>
              this.props.actions.newTaskComment(this.props.userAuth.id, {
                TASK_OBJID: this.props.taskData.task.OBJID,
                REASON: this.props.taskData.task.REASON,
                CHG_USER: this.props.userAuth.id,
                CHG_TYPE: changeType,
                CHG_FROM: changeFrom,
                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                NOTE: note,
                FILE: file,
                GPS: this.props.geolocation,
              }),
          }}
        />
        <TaskSettingsModal
          open={this.state.settingModal}
          close={() => this.setState({ settingModal: false })}
          selectedIndex={this.state.selectedIndex}
          language={this.props.language}
          updatePriority={(priority, id) => this.updatePriority(priority, id)}
          permissionsCheck={this.state.permissionsCheck}
          isAuthenticated={this.props.isAuthenticated}
          setTaskModal={() => this.setState({ taskModalOpen: true })}
        />
        {this.props.isFetchingTaskList === false ? (
          <React.Fragment>
            <TabSelector
              language={this.props.language}
              value={this.state.selectedTab}
              handleChange={(newValue) =>
                this.setState({ selectedTab: newValue })
              }
              userPermissions={this.props.permissions}
              isAuthenticated={this.props.isAuthenticated}
            />
            {this.state.selectedTab === 1 && (
              <TasksSetup
                language={this.props.language}
                data={this.props.taskList}
                theme={this.props.theme}
                rowsPerPage={this.props.rowsPerPage}
                onRowsPerPageChange={(value) =>
                  this.props.actions.onRowsPerPageChange(value)
                }
                openRowModal={(value) =>
                  this.setState({ selectedIndex: value, settingModal: true })
                }
                fetchData={areaList => this.getActiveTaskList(areaList)}
              />
            )}
            {this.state.selectedTab === 2 && <TaskHistory />}
            {this.state.selectedTab === 3 && (
              <TaskBillValidation language={this.props.language} />
            )}
            {this.state.selectedTab === 4 && (
              <TaskBillCashingValidation
                language={this.props.language}
                userPermissions={this.props.permissions}
              />
            )}
          </React.Fragment>
        ) : (
          <TableSkeleton />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.config.language,
    theme: state.config.theme,
    isFetchingTaskList: state.task.isFetchingTaskList,
    geolocation: state.config.geolocation,
    taskList: state.task.taskList,
    loadedPages: state.config.loadedPages,
    rowsPerPage: state.config.rowsPerPage,
    sortColumn: state.config.sortColumn,
    currentPage: state.config.currentPage,
    order: state.config.order,
    isAuthenticated: state.auth.isAuthenticated,
    permissions: state.auth.permissions,
    userAuth: state.auth.userData,
    isFetchingTaskData: state.task.isFetchingTaskData,
    taskData: state.task.taskData,
    selectedSortItem: state.config.selectedSortItem,
    siteData: state.cust.siteData,
    isFetchingCustomerData: state.cust.isFetchingCustomerData,
    isFetchingSiteData: state.cust.isFetchingSiteData,
    customerData: state.cust.customerData,
    isUploadingFile: state.task.isUploadingFile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskView);
