import React, { Component } from 'react';
import { Grid, CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../actions/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MaterialTableDemo from '../../../Components/Misc/Tables/MaterialTableSimple';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { execNom } from '../../../Components/Misc/Tables/TableHeaders'
import * as lang from '../../../Constants/strings';
import CustomModal from '../../../Components/Modals/CustomModal';
import ExecNewForm from './ExecNomComponents/ExecNewForm';
import TableSkeleton from '../Skeleton';
import { contractOptions } from '../../../Constants/misc';
import EditModal from './ExecNomComponents/ExecEditModal';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    divier: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
}));

function ExecutorNomSetup(props) {
    const classes = useStyles();
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <Grid container component="main" className={classes.root} spacing={tiny ? 1 : 4}>
            <Grid item xs={12}>
                <MaterialTableDemo
                    tiny={tiny}
                    title={lang.EXEC_TABLE[props.language]}
                    language={props.language}
                    data={props.execList}
                    rowsPerPage={props.rowsPerPage}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    header={execNom(props.language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={props.openRowModal}
                    clickLabel="OBJID"
                />
            </Grid>
        </Grid>
    );
}

class ExecutorNom extends Component {
    state = {
        execList: [],
        addModal: false,
        selectedIndex: null,
        addData: {
            NAME: "",
            OPT: [],
            IS_DEFAULT: false,
        },
        error: null,
    }

    componentDidMount() {
        document.title = "RPG - " + lang.EXEC_TABLE[this.props.language];
        this.props.actions.getExecutorList();
    }
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <CustomModal
                    fullWidth
                    open={this.state.addModal}
                    title={lang.ADD_EXEC[this.props.language]}
                    content={
                        <ExecNewForm
                            contractOptions={contractOptions}
                            addData={this.state.addData}
                            updateField={(e, lenght) => {
                                if (e.target.name !== "OPT") {
                                    this.setState({ addData: { ...this.state.addData, [e.target.name]: e.target.value.toString().slice(0, lenght) } });
                                }
                                else {
                                    this.setState({ addData: { ...this.state.addData, OPT: e.target.value } }, console.log(this.state.addData.OPT))
                                }
                            }}
                            updateFieldRaw={(field, value) => this.setState({ addData: { ...this.state.addData, [field]: value } }, () => console.log(this.state.addData[field]))}
                            language={this.props.language}
                            error={this.state.error}
                            updateError={(value) => this.setState({ error: value })}
                        />
                    }
                    close={() => {
                        this.setState({
                            addModal: false,
                            error: null,
                        })
                    }}
                    execute={() => {
                        this.props.actions.addNewExecutor(this.props.userAuth.id, {
                            NAME: this.state.addData.NAME,
                            PHONE: this.state.addData.PHONE ? this.state.addData.PHONE : null,
                            EMAIL: this.state.addData.EMAIL ? this.state.addData.EMAIL : null,
                            ADDRESS: this.state.addData.ADDRESS ? this.state.addData.ADDRESS : null,
                            NR_REG: this.state.addData.NR_REG ? this.state.addData.NR_REG : null,
                            CUI: this.state.addData.CUI ? this.state.addData.CUI : null,
                            OPT: this.state.addData.OPT.map(item => {
                                return {
                                    "TYPE": parseInt(item.split("-")[0], 10),
                                    "SUB_TYPE": parseInt(item.split("-")[1], 10)
                                }
                            }),
                            IS_DEFAULT: this.state.addData.IS_DEFAULT ? 1 : 0,
                        });
                        this.setState({
                            addModal: false,
                            error: null,
                        })
                    }}
                    validate={this.state.error === false && this.state.addData.OPT.length > 0}
                    language={this.props.language}
                />
                <EditModal
                    authID={this.props.userAuth.id}
                    language={this.props.language}
                    selectedIndex={this.state.selectedIndex}
                    updateExecutor={(data) => this.props.actions.updateExecutor(this.props.userAuth.id, { ...data, selectedIndex: this.state.selectedIndex })}
                    toggleExecutor={(data) => this.props.actions.toggleExecutor(this.props.userAuth.id, data)}
                    close={() => this.setState({ selectedIndex: null })}
                />
                {this.props.isFetchingExecutorList === false
                    ?
                    <ExecutorNomSetup
                        language={this.props.language}
                        execList={this.props.executorList}
                        rowsPerPage={this.props.rowsPerPage}
                        onRowsPerPageChange={(value) => this.props.actions.onRowsPerPageChange(value)}
                        openAddModal={() => this.setState(
                            {
                                addModal: true,
                                addData: {
                                    NAME: "",
                                    OPT: [],
                                    IS_DEFAULT: false,
                                }
                            })}
                        openRowModal={(value) => this.setState({ selectedIndex: value })}
                    />
                    :
                    <TableSkeleton />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        rowsPerPage: state.config.rowsPerPage,
        permissions: state.auth.permissions,
        userAuth: state.auth.userData,

        executorList: state.nom.executorList,
        isFetchingExecutorList: state.nom.isFetchingExecutorList,
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ExecutorNom);