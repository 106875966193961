import React from 'react';
import { makeStyles, Grid, Button, Fab, MenuItem, Menu, Typography, Paper, Grow } from '@material-ui/core';
import GroupButtonSet from '../../../Components/Misc/GroupButtonSet';
import { AddRounded, EditRounded, VisibilityRounded, CheckRounded, Receipt, VerifiedUserRounded, DescriptionRounded, LocalShippingRounded, AssignmentLateRounded, AssignmentIndRounded, PauseRounded, ForwardRounded, DoneRounded, CancelRounded, ShortText } from '@material-ui/icons';
import * as lang from '../../../Constants/strings';
import CustomDrawer from '../../../Components/Modals/CustomDrawer';
import Skeleton from '@material-ui/lab/Skeleton';
import { checkPermissions } from '../../../Functions/permissionHide';


const useStyles = makeStyles(theme => ({
    leftIcon: {
        marginRight: theme.spacing(2),
    },
    rightIcon: {
        marginLeft: theme.spacing(2),
    },
    float: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 99,
    },
    menuIcon: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-0.5),
    },
    paperClear: {
        padding: theme.spacing(2),
        height: '100%',
    },
}));

export default function TaskButtons(props) {
    const classes = useStyles();
    const { language, tiny, task } = props;

    return (
        <React.Fragment>
            {task.loading
                ?
                <SkeletonTaskButtons classes={classes} />
                :
                <Grid item container direction="row" xs={12} lg={12} style={{ paddingRight: '0px' }}>
                    {task && task.data && task.data.task &&
                        <GroupButtonSet
                            tiny={tiny}
                            language={language}
                            style={{
                                // fullWidth: true,
                                size: "large",
                                color: "default",
                                variant: "contained",
                                disabled: false,
                            }}
                            list={[
                                {
                                    primary: task.userId === task.data.task.OWNER_CURR,
                                    text: lang.NEXT_STATUS(task.data.task.STATUS)[language],
                                    style: {
                                        // variant: 'contained',
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1 || ["STARTED"].indexOf(task.data.task.STATUS) !== -1 && props.taskData.CONTRACT_OBJID === null,
                                    },
                                    icon: <ForwardRounded />,
                                    render: task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }),
                                    action: () => props.openAction(task.data.task.STATUS_ID, false),
                                },
                                {
                                    text: lang.LOAD_IMAGE[language],
                                    icon: <PauseRounded />,
                                    style: {
                                        // variant: 'contained',
                                        disabled: props.taskData.CONTRACT_OBJID === null,
                                    },
                                    render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1 && task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }) && task.data.task.FK2BILL !== null,
                                    action: () => props.openAction("LOAD_IMAGE", 8),
                                },
                                {
                                    text: lang.SELECT_CONTRACT[language],
                                    style: {
                                        color: "secondary",
                                        disabled: props.taskData.CONTRACT_OBJID !== null,
                                    },
                                    icon: <Receipt />,
                                    render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1 &&
                                        task.userId === task.data.task.OWNER_CURR &&
                                        checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }),
                                    action: () => props.openContract()
                                },
                                {
                                    primary: task.userId !== task.data.task.OWNER_CURR,
                                    text: lang.ASSIGN_TO_ME[language],
                                    style: {
                                        // variant: 'contained',
                                        disabled: ["OPEN", "ON_HOLD"].indexOf(task.data.task.STATUS) === -1,
                                    },
                                    icon: <AssignmentIndRounded />,
                                    leftIcon: <AssignmentIndRounded fontSize="small" className={classes.leftIcon} />,
                                    render: task.userId !== task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }),
                                    action: () => props.openAction("ASSIGN", task.data.task.STATUS_ID),
                                },
                                {
                                    primary: task.userId !== task.data.task.OWNER_CURR,
                                    text: lang.CANCEL_ALOCATION[language],
                                    style: {
                                        // variant: 'contained',
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1 || ["ON_HOLD"].indexOf(task.data.task.STATUS) !== -1,
                                    },
                                    icon: <AssignmentIndRounded />,
                                    leftIcon: <AssignmentIndRounded fontSize="small" className={classes.leftIcon} />,
                                    render: ["CLOSED", "CANCELLED", "ON_HOLD"].indexOf(task.data.task.STATUS) === -1 &&
                                        ((task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" })) ||
                                            (checkPermissions(props.userPermissions, { parent: "TASKS", name: "SUPER_RESET_TASK" }))),
                                    action: () => props.openResetTask()
                                },
                                {
                                    text: lang.UPDATE_REASON[language],
                                    icon: <ShortText />,
                                    style: {
                                        color: "primary",
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1
                                    },
                                    render: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) === -1 && checkPermissions(props.userPermissions, { parent: "TASKS", name: "UPDATE_TASK_REASON" }),
                                    action: () => props.openUpdateReason(),
                                },
                                // {
                                //     text: lang.LOAD_IMAGE[language],
                                //     icon: <PauseRounded />,
                                //     render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1 && task.userId === task.data.task.OWNER_CURR && checkPermissions(props.userPermissions, { parent: "TASKS", name: "TAKE_TASK" }),
                                //     action: () => props.openAction("LOAD_IMAGE", 8),
                                // },
                                {
                                    text: lang.CANCEL[language],
                                    style: {
                                        color: "secondary",
                                        disabled: ["CLOSED", "CANCELLED"].indexOf(task.data.task.STATUS) !== -1
                                    },
                                    icon: <CancelRounded />,
                                    rightIcon: <CancelRounded fontSize="small" className={classes.rightIcon} />,
                                    render: ["CANCELLED"].indexOf(task.data.task.STATUS) === -1 && checkPermissions(props.userPermissions, { parent: "TASKS", name: "CANCEL_TASK" }),
                                    action: () => props.openAction("CANCELLED", 7),
                                },
                                // {
                                //     text: lang.CLOSE[language],
                                //     style: {
                                //         color: "secondary",
                                //     },
                                //     icon: <DoneRounded />,
                                //     rightIcon: <DoneRounded fontSize="small" className={classes.rightIcon} />,
                                //     render: ["STARTED"].indexOf(task.data.task.STATUS) !== -1,
                                //     action: () => {console.log()},
                                // },
                            ]}
                        />
                    }
                </Grid>
            }
        </React.Fragment>
    );
}
export function SkeletonTaskButtons(props) {
    const { classes } = props;
    return (
        <Grid item xs={12} md={8} style={{ paddingRight: '0px' }} >
            <Skeleton component={Paper} className={classes.paperClear}>
                <Grid container justify="center" alignItems="center" direction="column">
                    {/* <Skeleton width="15%" className={classes.title} /> */}
                </Grid>
            </Skeleton>
        </Grid>
    );
}