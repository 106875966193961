import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateFnsUtils from '@date-io/date-fns';
import {
    VisibilityRounded,
    CalendarToday,
    CheckCircle,
    RemoveCircleOutline,
    AttachMoney,
} from '@material-ui/icons';
import {
    makeStyles,
    CssBaseline,
    useTheme,
    Grid,
    useMediaQuery,
    Select,
    MenuItem,
    Fab,
    Button,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker,
} from '@material-ui/pickers';

import * as actionCreators from '../../actions/actions';
import * as lang from '../../Constants/strings';
import { tasks_history } from '../../Components/Misc/Tables/TableHeaders';
import { downloadFile } from '../../Functions/fileDownload';
import { formatDateFromDb } from '../../Functions/dateFunctions';
import CustomButtonList from '../../Components/Modals/CustomButtonList';
import CustomModal from '../../Components/Modals/CustomModal';
import TableSkeleton from '../Nomenclature/Skeleton';
import CustomDrawer from '../../Components/Modals/CustomDrawer';
import MaterialTable from '../../Components/Misc/Tables/MaterialTableSimple';
import InstallationModal from "./InstallationModal";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(10),
        width: 'auto'
    },
    shortcut: {
        marginBottom: theme.spacing(10),
        margin: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(6, 2, 6, 2),
        width: 'auto'
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    fabGrid: {
        margin: theme.spacing(-5, 0, 0, 0),
    },
}));

function InstallationSetup(props) {
    const classes = useStyles();
    const { language, data, area, openDateModal, startDate, endDate } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid container component="main" className={classes.margin}>
            <Grid container component="main" className={classes.fabGrid} justify='flex-start' alignItems='stretch' spacing={0} >
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        size={tiny ? 'medium' : 'small'}
                        color="primary"
                        aria-label="add"
                        onClick={() => openDateModal()}
                    >
                        <CalendarToday
                            className={classes.leftIcon}
                        />
                        {lang.DATE_SELECT[language]}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable
                    tiny={tiny}
                    title={
                        area === '0' ? lang.TASKS[language] + ' din ' + startDate + '  până  ' + endDate : lang.TASKS[language] + ' din ' + startDate + '  până  ' + endDate + ` pe zona ${area}`
                    }
                    language={language}
                    data={data}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(value) => setRowsPerPage(value)}
                    header={tasks_history(language)}
                    dense={false}
                    openAddModal={props.openAddModal}
                    openRowModal={(id) => props.openRowModal(id)}
                    clickLabel="TASK_ID"
                    hideAddBtn={true}
                />
            </Grid>
        </Grid>
    )
};

function TaskSettingsModal(props) {
    const { language, close, open } = props;
    const theme = useTheme();
    const tiny = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    return (
        <React.Fragment>
            <CustomDrawer
                open={open && tiny}
                close={close}
                list={[
                    {
                        primary: lang.GO_TO(lang.TASK[language])[language],
                        secondary: "",
                        icon: <VisibilityRounded />,
                        action: () => props.setTaskModal(),
                        render: props.permissionsCheck[0]
                    }
                ]}
            />
            <CustomModal
                open={open && !tiny}
                title={lang.TASKS[language]}
                content={
                    <Grid container spacing={2}>
                        <CustomButtonList
                            close={close}
                            language={language}
                            tiny={tiny}
                            list={[
                                {
                                    text: lang.GO_TO(lang.TASK[language])[language],
                                    style: {
                                        icon: <VisibilityRounded className={classes.leftIcon} />,
                                        color: "primary",
                                        variant: "contained",
                                    },
                                    action: () => props.setTaskModal(),
                                    render: props.permissionsCheck[0]
                                },
                            ]}
                            withBack
                        />
                    </Grid>
                }
                close={close}
                execute={close}
                language={language}
                noButtons
            />
        </React.Fragment>
    );
};

class InstallationHistory extends Component {
    state = {
        isFetchingHistory: true,
        taskList: [],
        searchTerm: null,
        sortColumn: null,
        selectedArea: '0',
        selectDateModal: false,
        selectAreaModal: false,
        startDate: null,
        endDate: null,
        selectedIndex: null,
        settingModal: false,
        taskModalOpen: false,
        trainees: [{
            NAME: "",
            SIGN: "",
        }],

        project: {
            NAME: "",
            NUMBER: "",
            SIGN: "",
        },
        showProject: false,
        journal: {
            NAME: "",
            NUMBER: "",
            SIGN: "",
        },
        opt: [false, false, false, false],
        showJournal: false,
        billModal: false,
        permissionsCheck: [],
        InstallationModalOpen: false
    };

    componentWillMount() {
        let date = new Date;
        this.props.actions.getAreaList();
        this.setState({ startDate: new Date(), endDate: date, selectedArea: '0' });
        this.getTaskHistoryList(formatDateFromDb(date, 8), formatDateFromDb(date, 8), 0);
    }

    getTaskHistoryList(startDate, endDate, area) {
        this.setState({ isFetchingHistory: true })
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'authorization': localStorage.token,
            },
        }

        return fetch(`/api/close_installations?startDate=${startDate}&endDate=${endDate}&areaCode=${area}`, options)
            .then(response => {
                switch (response.status) {
                    case 403: {
                        if (localStorage.token) {
                            this.props.actions.OpenConfirmWindow();
                        }
                        else {
                            this.props.actions.LogoutAndRedirect();
                        }
                        return response.json();
                    }
                    default: return response.json();
                }
            })
            .then(response => {
                if (response.error === 1) {
                    this.setState({ taskList: [], isFetchingHistory: false })
                    this.props.actions.onNotificationOpen("warning", lang[response.message], 6000)
                }
                else {
                    const list = [];
                    response.data.forEach(item => {
                        list.push({
                            ...item,
                            END_DATE: formatDateFromDb(item.END_DATE, 8),
                            BILL_STATUS: (item.BILL_VALIDATION === 1 && item.RECEIPT_VALIDATION === 1) && < CheckCircle color='primary' /> || item.RECEIPT_VALIDATION === 1 && <AttachMoney color='secondary' /> || <RemoveCircleOutline color='secondary' />,
                            TYPE_TRANS: item.TYPE !== null ? lang[item.TYPE][this.props.language] : '',
                        })
                    })
                    this.setState({ taskList: list, isFetchingHistory: false })
                }
            }).then(() => {
                this.props.actions.SignInUser(false, false, false, true);
            }).catch(err => {
                this.setState({ taskList: [], isFetchingHistory: false })
                console.log('caught it!', err);
            })
    }

    handleSelect(e) {
        e.persist();
        this.setState({ selectedArea: e.target.value });
    };

    updateProject = (field, value) => {
        if (field === 'SHOW')
            this.setState({ showProject: !this.state.showProject });
        else {
            let placeholder = this.state.project;
            placeholder[field] = value;
            this.setState({ project: placeholder });
        }
    };

    updateJournal = (field, value) => {
        if (field === 'SHOW')
            this.setState({ showJournal: !this.state.showJournal });
        else {
            let placeholder = this.state.journal;
            placeholder[field] = value;
            this.setState({ journal: placeholder });
        }
    };

    resetProject = () => {
        this.setState({ project: { NAME: "", NUMBER: "", SIGN: "" }, showProject: false })
    };

    resetJournal = () => {
        this.setState({ journal: { NAME: "", NUMBER: "", SIGN: "" }, showJournal: false })
    };

    addRemoveTrainee = (index) => {
        let placeholder = this.state.trainees;
        if (index === false)
            placeholder.push({ NAME: "", SIGN: "" });
        else
            placeholder.splice(index, 1);
        this.setState({ trainees: placeholder });
    };
    updateTrainee = (index, field, value) => {
        let placeholder = this.state.trainees;
        placeholder[index][field] = value;
        this.setState({ trainees: placeholder }, console.log(this.state.trainees));
    };

    resetTrainees = () => {
        this.setState({ trainees: [{ NAME: "", SIGN: "" }] });
    };

    updateOpt = (index) => {
        let placeholder = this.state.opt;
        placeholder[index] = !placeholder[index];
        this.setState({ opt: placeholder }, console.log(this.state.opt));
    };

    resetOpt = () => {
        this.setState({ opt: [false, false, false, false] })
    };

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <InstallationModal
                    siteData={this.props.siteData}
                    language={this.props.language}
                    open={this.state.InstallationModalOpen}
                    close={() => {
                        this.setState({ InstallationModalOpen: false });
                    }}
                    installationId={this.state.selectedIndex}
                    user={this.props.userAuth}
                    userPermissions={this.props.permissions}
                    isAuthenticated={this.props.isAuthenticated}
                    onNotificationOpen={(type, message) =>
                        this.props.actions.onNotificationOpen(type, lang[message], 6000)
                    }
                    addCustomerContract={(data) => {
                        this.props.actions.addCustomerContract(
                            this.props.userAuth.id,
                            {
                                ...data,
                                SITE_OBJID: this.props.siteData.OBJID,
                                SALE_CONTACT: this.props.userAuth.id,
                            },
                            this.props.language
                        );
                    }}
                    isUploadingFile={this.props.isUploadingFile}
                    cust={{
                        data: this.props.customerData,
                        loading:
                            this.props.isFetchingCustomerData ||
                            this.props.isFetchingSiteData,
                        taskData: this.props.taskData,
                        initialLoad: this.props.taskData.task
                            ? () =>
                                this.props.actions.getCustomerData(
                                    this.props.taskData.task.CONTACT_OBJID
                                )
                            : () => console.log("No customer id"),
                    }}
                    task={{
                        opt: this.state.opt,
                        updateOpt: this.updateOpt,
                        resetOpt: this.resetOpt,
                        project: this.state.project,
                        journal: this.state.journal,
                        showProject: this.state.showProject,
                        showJournal: this.state.showJournal,
                        updateProject: this.updateProject,
                        updateJournal: this.updateJournal,
                        journalData: this.state.journalData,
                        updateJournalData: this.updateJournalData,
                        resetJournalData: this.resetJournalData,
                        resetProject: this.resetProject,
                        resetJournal: this.resetJournal,
                        trainees: this.state.trainees,
                        updateTrainee: this.updateTrainee,
                        addRemoveTrainee: this.addRemoveTrainee,
                        resetTrainees: this.resetTrainees,
                        userId: this.props.userAuth.id,
                        data: this.props.taskData,
                        loading: this.props.isFetchingTaskData,
                        downloadFile: downloadFile,
                        fetchData: async () => {
                            await this.props.actions.getTaskData(this.state.selectedIndex);
                            await this.props.actions.getSiteData(this.props.taskData.task.SITE_OBJID);
                        },
                        newTaskAction: this.newTaskAction,
                        resetTaskAction: (changeType, changeFrom, changeTo, note) =>
                            this.props.actions.resetTaskAction(this.props.userAuth.id, {
                                TASK_OBJID: this.props.taskData.task.OBJID,
                                REASON: this.props.taskData.task.REASON,
                                CHG_USER: this.props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                                NOTE: note,
                            }),
                        newTaskComment: (changeType, changeFrom, changeTo, note, file) =>
                            this.props.actions.newTaskComment(this.props.userAuth.id, {
                                TASK_OBJID: this.props.taskData.task.OBJID,
                                REASON: this.props.taskData.task.REASON,
                                CHG_USER: this.props.userAuth.id,
                                CHG_TYPE: changeType,
                                CHG_FROM: changeFrom,
                                CHG_TO: changeType !== 2 ? changeTo : this.props.userAuth.id,
                                NOTE: note,
                                FILE: file,
                            }),
                    }}
                />
                <TaskSettingsModal
                    open={this.state.settingModal}
                    close={() => this.setState({ settingModal: false })}
                    selectedIndex={this.state.selectedIndex}
                    language={this.props.language}
                    permissionsCheck={this.state.permissionsCheck}
                    isAuthenticated={this.props.isAuthenticated}
                    setTaskModal={() => this.setState({ taskModalOpen: true })}
                    setBillModal={() => this.setState({ billModal: true })}
                />
                <CustomModal
                    open={this.state.selectDateModal}
                    title={lang.SELECT_INTERVAL_DATE[this.props.language]}
                    close={() => this.setState({ selectDateModal: false })}
                    content={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        required
                                        inputVariant="outlined"
                                        fullWidth
                                        name="START_DATE"
                                        label={lang.START_INTERVAL_DATE[this.props.language]}
                                        format="dd-MM-yyyy"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ margin: 10 }}
                                        value={this.state.startDate}
                                        onChange={(value) => this.setState({ startDate: value })}
                                    />
                                </MuiPickersUtilsProvider> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        style={{ margin: 10 }}
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline-1"
                                        label={lang.START_INTERVAL_DATE[this.props.language]}
                                        value={this.state.startDate}
                                        onChange={(value) => this.setState({ startDate: value })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}  >
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        disableToolbar
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        format="dd-MM-yyyy"
                                        id="date-picker-inline-1"
                                        style={{ margin: 10 }}
                                        label={lang.LAST_INTERVAL_DATE[this.props.language]}
                                        value={this.state.endDate}
                                        onChange={date => this.setState({ endDate: date })}
                                    />
                                </MuiPickersUtilsProvider> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        style={{ margin: 10 }}
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        id="date-picker-inline-1"
                                        label={lang.LAST_INTERVAL_DATE[this.props.language]}
                                        value={this.state.endDate}
                                        onChange={(value) => this.setState({ endDate: value })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    }
                    execute={() => this.getTaskHistoryList(formatDateFromDb(this.state.startDate, 15), formatDateFromDb(this.state.endDate, 15), this.state.selectedArea) && this.setState({ selectDateModal: false })}
                    validate
                    language={this.props.language}
                />
                <CustomModal
                    open={this.state.selectAreaModal}
                    title={lang.AREA_SELECT[this.props.language]}
                    close={() => this.setState({ selectAreaModal: false })}
                    content={
                        <Grid container spacing={2} justify='center' alignItems='stretch'>
                            <Select
                                value={this.state.selectedArea}
                                onChange={e => this.handleSelect(e)}
                                label={lang.AREA_SELECT[this.props.language]}
                            >
                                <MenuItem value={0}>Niciuna</MenuItem>
                                {this.props.areaList.map(area => {
                                    return <MenuItem key={area.AREA} value={area.AREA}>{area.AREA}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                    }
                    execute={() => this.getTaskHistoryList(formatDateFromDb(this.state.startDate), formatDateFromDb(this.state.endDate), this.state.selectedArea) && this.setState({ selectAreaModal: false })}
                    validate
                    language={this.props.language}
                />
                {
                    this.state.isFetchingHistory ?
                        <TableSkeleton /> :
                        <InstallationSetup
                            data={this.state.taskList}
                            language={this.props.language}
                            tiny={false}
                            openRowModal={(value) => this.setState({ selectedIndex: value, InstallationModalOpen: true })}
                            area={this.state.selectedArea}
                            startDate={formatDateFromDb(this.state.startDate, 8)}
                            endDate={formatDateFromDb(this.state.endDate, 8)}
                            openDateModal={() => {
                                this.setState({ selectDateModal: true })
                            }}
                            openAreaModal={() => {
                                this.setState({ selectAreaModal: true })
                            }}
                            reset={() => {
                                let date = new Date;
                                this.getTaskHistoryList(formatDateFromDb(date, 8), formatDateFromDb(date, 8), 0);
                                this.setState({ startDate: date, endDate: date, selectedArea: "0" });
                            }}
                        />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.config.language,
        theme: state.config.theme,
        areaList: state.nom.areaList,
        isUploadingFile: state.task.isUploadingFile,
        isAuthenticated: state.auth.isAuthenticated,
        permissions: state.auth.permissions,
        siteData: state.cust.siteData,
        userAuth: state.auth.userData,
        taskData: state.task.taskData,
        isFetchingTaskData: state.task.isFetchingTaskData,
        isFetchingCustomerData: state.cust.isFetchingCustomerData,
        customerData: state.cust.customerData,
    }
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallationHistory);
