import React, { useState } from "react";
import axios from "axios";
import {
	useMediaQuery,
	useTheme,
	Grid,
	makeStyles,
} from "@material-ui/core";
import * as lang from "../../../Constants/strings";
import * as requestHeaders from "../../../Constants/requestHeaders";
import CustomModal from "../../../Components/Modals/CustomModal";
import InstallationDetails from "./InstallationDetails";
import AssignMeModal from "./Modals/AssignMeModal";
import ActionModal from "./Modals/ActionModal";
import CancelModal from "./Modals/CancelModal";
import ResetAlocationModal from "./Modals/ResetAlocationModal";
import SetReasonModal from "./Modals/SetReasonModal";
import CloseWithImage from "../Modals/CloseWithImage";
import ModalRegisterTaskProgress from "../../Site/SiteSingleViewComponents/Modals/ModalRegisterTaskProgress";
import GPSGuard from "../../../Components/GPSGuard";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		width: "100%",
	},
	avatar: {
		width: 50,
		height: 50,
	},
	title: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	comment: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	commentAuto: {
		color: "gray",
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(0),
	},
	timestamp: {
		marginTop: theme.spacing(1.5),
	},
	divider: {
		margin: theme.spacing(2),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	buttonGroup: {
		marginTop: theme.spacing(1),
	},
	iconRight: {
		marginLeft: theme.spacing(1),
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
	signature: {
		backgroundColor: "white",
		width: "100%",
		height: "50vh",
	},
	float: {
		margin: theme.spacing(1),
	},
}));

const InstallationModal = (props) => {
	const {
		open,
		close,
		language,
		userPermissions,
		isAuthenticated,
		onNotificationOpen,
		installationId,
		user,
	} = props;
	const classes = useStyles();

	const [installation, setInstallation] = useState({});
	const [file, setUploadedFile] = useState(null);
	const [uploadModal, setUploadModal] = useState(false);
	const [actionModal, setActionModal] = useState(false);
	const [assignToMeModal, setAssignToMeModal] = useState(false);
	const [cancelModal, setCancelModal] = useState(false);
	const [resetAlocationModal, setResetAlocationModal] = useState(false);
	const [reasonUpdateModal, setReasonUpdateModal] = useState(false);
	const [sendingComment, setSendingComment] = useState(false);
	const [commentText, setCommentText] = React.useState("");
	const [showCommentBox, setShowCommentBox] = React.useState(false);
	const [closeWithImageModal, setCloseWithImageModal] = React.useState(false);
	const [modalProgress, setModalProgress] = React.useState(false);

	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const isSm = useMediaQuery(theme.breakpoints.down("sm"));

	const getInstallation = async () => {
		try {
			const response = await axios.get("/api/get_installation", {
				...requestHeaders.getGetHeader(),
				params: {
					id: installationId
				}
			});
			setInstallation(response.data);
		} catch (error) {
			setInstallation({})
		}
	}

	React.useEffect(() => {
		if (installationId && installationId !== null) getInstallation();
	}, [installationId]);

	const newComment = async (note) => {
		setSendingComment(true);
		try {
			await axios.post("/api/installation_comment", {
				TASK_OBJID: installationId,
				CHG_USER: user.id,
				NOTE: note,
				FILE: file !== null ? file : null,
			}, requestHeaders.getPostHeader);
			getInstallation();
			setUploadedFile(false);
			setSendingComment(false);
			setCommentText("");
			showCommentBox(false);
		} catch (error) {
			setSendingComment(false);
		}
	};

	const openCloseWithImage = (type) => {
		try {

		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<GPSGuard />
			<CustomModal
				key={"hello"}
				fullScreen
				open={open}
				title={`#${installationId} - ${lang.INSTALLATION[language]}`}
				content={
					<Grid container spacing={2}>
						{open && (
							<>
								<InstallationDetails
									classes={classes}
									site={installation && installation.site ? installation.site : {}}
									data={installation}
									language={language}
									taskId={installationId}
									userId={user.id}
									tiny={tiny}
									isSm={isSm}
									customer={installation && installation.customer ? installation.customer : {}}
									userPermissions={userPermissions}
									isAuthenticated={isAuthenticated}
									onNotificationOpen={(data, message) => onNotificationOpen(data, message)}
									refetchTaskData={() => getInstallation()}
									newComment={(note) => newComment(note)}
									setUploadModal={(value) => setUploadModal(value)}
									setFile={(base64File) => setUploadedFile(base64File)}
									uploadModalStatus={uploadModal}
									openActionModal={() => setActionModal(true)}
									openAsignToMeModal={() => setAssignToMeModal(true)}
									openActionCancelModal={() => setCancelModal(true)}
									openResetAlocationModal={() => setResetAlocationModal(true)}
									openUpdateReasonModal={() => setReasonUpdateModal(true)}
									sendingComment={sendingComment}
									commentText={commentText}
									showCommentBox={showCommentBox}
									handleCommentTextChange={value => setCommentText(value)}
									handleCommentBoxShow={value => setShowCommentBox(value)}
									openCloseWithImage={() => setCloseWithImageModal(true)}
									openProgressModal={() => setModalProgress(true)}
								/>
								<ActionModal
									open={actionModal}
									close={() => setActionModal(false)}
									language={language}
									installation={installation?.installations}
									refetchTaskData={() => getInstallation()}
									onNotificationOpen={(data, message) => onNotificationOpen(data, message)}
									userId={user.id}
								/>
								<AssignMeModal
									open={assignToMeModal}
									close={() => setAssignToMeModal(false)}
									language={language}
									installationId={installationId}
									userId={user.id}
									refetch={() => getInstallation()}
								/>
								<CancelModal
									open={cancelModal}
									close={() => setCancelModal(false)}
									language={language}
									installationId={installationId}
									userId={user.id}
									refetch={() => getInstallation()}
								/>
								<ResetAlocationModal
									open={resetAlocationModal}
									close={() => setResetAlocationModal(false)}
									language={language}
									installationId={installationId}
									userId={user.id}
									refetch={() => getInstallation()}
								/>
								<SetReasonModal
									open={reasonUpdateModal}
									close={() => setReasonUpdateModal(false)}
									language={language}
									installationId={installationId}
									refetch={() => getInstallation()}
									userId={user.id}
								/>
								<CloseWithImage
									open={closeWithImageModal}
									close={() => setCloseWithImageModal(false)}
									installationId={installationId}
									language={language}
									refetch={() => getInstallation()}
									userId={user.id}
								/>
								<ModalRegisterTaskProgress
									open={modalProgress}
									onClose={async () =>  {
										await getInstallation();
										setModalProgress(false);
									}}
									language={language}
									taskId={installationId}
								/>
							</>
						)}
					</Grid>
				}
				close={close}
				language={language}
				execute={() => console.log("here")}
				noButtons
				onlyBack
			/>
		</>
	)
};

export default InstallationModal;
