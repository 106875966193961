import React from 'react';
import { Grid, Avatar, Typography, Divider, Box, IconButton, Button, Chip, Tooltip } from '@material-ui/core';
import getAvatarUri from '../../../Functions/getDataUriAvatar';
import { formatDateFromDb } from '../../../Functions/dateFunctions';
import { openPageNewTab } from '../../../Functions/linkFunctions';
import * as lang from '../../../Constants/strings';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { GpsFixedRounded } from '@material-ui/icons';
import { getMimeType } from '../../../Functions/mimeType';
import getGoogleMapLink from '../../../Functions/getGoogleMapLink';

export default function Comment(props) {
    const { data, language, classes, isIntervention } = props;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid container direction="row">
                <Grid container item xs={1} justify="center">
                    <Avatar onClick={() => openPageNewTab(`/profile?id=${data.CHG_USER}`)} style={{ cursor: "pointer" }} alt={data.AVATAR_ID.toString()} src={getAvatarUri(data.AVATAR_ID)} className={classes.avatar} />
                </Grid>
                <Grid item xs={11} container direction="column">
                    <Grid item xs={'auto'}>
                        <Grid container direction="row">
                            <Box style={{ cursor: "pointer" }} onClick={() => openPageNewTab(`/profile?id=${data.CHG_USER}`)}>
                                <Typography variant="body1" className={classes.title}>
                                    <strong>
                                        {data.USERNAME}
                                    </strong>
                                </Typography>
                            </Box>
                            <Typography variant="caption" className={classes.timestamp}>
                                {formatDateFromDb(data.CREATE_TIME, 13)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {data.CHANGE && data.CHANGE !== "COMMENT" &&
                        <Grid item xs={'auto'}>
                            {isIntervention === false ?
                                <Typography variant="body2" className={classes.commentAuto} gutterBottom>
                                    {lang.SYSTEM_COMMENT(data.CHANGE, data.FROM, data.TO)[language]}{data.CHANGE === "ASSIGN" ? <strong>{data.FROM} - {data.TO}</strong> : ""}
                                </Typography>
                                :
                                <Typography variant="body2" className={classes.commentAuto} gutterBottom>
                                    {lang.SYSTEM_COMMENT_INTERVENTION(data.CHANGE, data.FROM, data.TO)[language]}{data.CHANGE === "ASSIGN" ? <strong>{data.FROM} - {data.TO}</strong> : ""}
                                </Typography>
                            }
                        </Grid>
                    }
                    {data.NOTE && data.NOTE.length > 0 &&
                        <Grid item xs={'auto'}>
                            <Typography variant="body2" className={classes.comment} gutterBottom>
                                {data.NOTE}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={'auto'} className={classes.comment}>
                        {data.LONGITUDE && data.LONGITUDE !== null &&
                            <Tooltip title={lang.POSITION[language]}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className={classes.commentButton}
                                    onClick={() => openPageNewTab(getGoogleMapLink(data.LATITUDE, data.LONGITUDE))}
                                >
                                    <GpsFixedRounded fontSize="small" className={classes.leftIcon} />
                                    {`~${data.ACCURACY}m`}
                                </Button>
                            </Tooltip>
                        }
                        {data.PDF_NAME && data.PDF_NAME.length > 0 &&
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.commentButton}
                                onClick={() => {
                                    if (data.PDF_NAME.split('_')[0] === 'COMMENT') {
                                        props.downloadFile(data.PDF_NAME);
                                    } else {
                                        fetch(`/api/download_pdf?file=${data.PDF_NAME}`, {
                                            method: "GET",
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'authorization': localStorage.token,
                                            }
                                        })
                                            .then(response => {
                                                if (response.status >= 400) {
                                                    console.log("Bad response from server");
                                                }
                                                return response.blob();
                                            })
                                            .then(response => {
                                                try {
                                                    const file = new Blob(
                                                        [response],
                                                        { type: 'application/pdf;' });
                                                    const fileURL = URL.createObjectURL(file);
                                                    var link = document.createElement('a');
                                                    link.href = fileURL;
                                                    link.download = `pv.pdf`
                                                    link.click()
                                                    // window.open(fileURL);
                                                }
                                                catch (e) { console.log('err') }
                                            })
                                            .catch(err => console.log(err))
                                    }
                                }}
                            >
                                <PictureAsPdfIcon fontSize="small" className={classes.leftIcon} />
                                {`${data.PDF_NAME} ${getMimeType(data.MIME_TYPE)}`}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}