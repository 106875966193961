export const TECHNICAL_TEAMS = {
  en: 'Technical teams',
  ro: 'Echipe tehnic'
}

export const CAR_NUMBER = {
  en: 'Car number',
  ro: 'Număr mașină'
}

export const ADD_TECHNICAL_TEAM = {
  en: 'Add technical team',
  ro: 'Crează echipaj'
}

export const TEAM_MEMBERS = {
  en: 'Team Members',
  ro: 'Membrii echipaj'
}

export const TEAM_NAME = {
  en: "Team name ",
  ro: "Nume echipaj "
}

export const CAN_NOT_CLOSE_SELECTING_TEAM_MODAL = {
  en: "Can't close the Selecting team modal",
  ro: "Nu poți să închizi modalul fără să selectezi membri echipajului!"
}

export const TECHNICAL_TEAM_SCORING = {
  en: "Technical team scoring",
  ro: "Punctaj echipaj technic"
}

export const POINTS = {
  en: "Points",
  ro: "Punctaj"
}

export const TECHNICAL_TEAM_MODAL_SCORE_MESSAGE = {
  en: "Adding team scores for technical task with id ",
  ro: "Adăugare scor echipă pentru task-ul cu id "
}

export const DISTANCE_KM = {
  en: "Distance (KM)",
  ro: "Distanță parcursă (KM)"
}

export const TASK_COMPLETE_VALUE = {
  en: "Task value",
  ro: "Valoare totală ticket"
}

export const TASK_REMAINING_VALUE = {
  en: "Task remaining value",
  ro: "Valoare rămasă ticket"
}

export const TASK_EXCEED_VALUE = {
  en: "The scores are bigger that the task value! Do you wish to continue?",
  ro: "Valorea totală este mai mare ca valoare task-ului! Vrei să continui?"
}

export const TECHNICAL_USER_HISTORY = {
  en: "Technical user history",
  ro: "Istoric punctaje utilizator technic"
}

export const TECHNICAL_USER = {
  en: "Technical user",
  ro: "Utilizator technic"
}

export const TECHNICAL_USER_SCORE = {
  en: "Technical user score",
  ro: "Punctaj echipaj technic"
}

export const TASK_SCORING_INCOMPLETE = {
  en: "Task score",
  ro: "Punctaj Echipaj"
}

export const TASK_SCORING_COMPLETE = {
  en: "Edit task score complete",
  ro: " Editare Punctaj Echipaj"
}

export const TECHNICAL_SCORES_VALIDATIONS = {
  en: "Technical scores validations",
  ro: "Validare punctaj"
}

export const BILL_VALIDATION_WITH_SCORE_UPDATE = {
  ro: "Validare factură / Validare punctaje",
  en: "Bill validation with score update"
}

export const DOWNLOAD_EXCEL = {
  ro: "Descarcă excel",
  en: "Download excel"
}