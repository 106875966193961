import React, { useRef } from "react";
import {Button, Grid} from "@material-ui/core";
import * as lang from "../../Constants/strings";

/**
 * @param onImageLoad -> function that receives the image as a dataUrl
 * @param maxWidth -: image max width as a number; the default is 550
 * @param language - current language
 * @param loading - boolean that display the loading
 * @returns {JSX.Element}
 * @constructor
 */
const ImageUploadField = (props) => {
    const inputRef = useRef(null);
    const canvasRef = useRef(null);

    const getInputData = (e) => {
        e.persist();
        e.preventDefault();
        if (e.target.files.length > 0) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            const url = URL.createObjectURL(e.target.files[0]);
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                props.onImageLoad(canvas.toDataURL("image/png"))
            }
            img.src = url;
        }
    }

    return (
        <Grid container spacing={2}>
            <input
                ref={inputRef}
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                tabIndex={-1}
                onChange={getInputData}
            />
            <Grid item xs={12}>
                <Button
                    fullWidth
                    style={{ marginTop: 10, marginBottom: 10 }}
                    variant='contained'
                    color='secondary'
                    onClick={() => inputRef.current.click()}
                >
                    {lang.LOAD_IMAGE[props.language]}
                </Button>
            </Grid>
            <Grid item xs={12} >
                <canvas
                    style={{
                        maxWidth: props.width ? parseInt(props.width, 10) : 550,
                        width: "100%",
                        background: "none"
                    }}
                    ref={canvasRef}
                />
            </Grid>
        </Grid>
    )
};

export default ImageUploadField;