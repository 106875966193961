import CustomModal from "../../../../Components/Modals/CustomModal";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getGetHeader, getPostHeader} from "../../../../Constants/requestHeaders";
import {Button, Chip, Divider, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const ModalRegisterTaskProgress = (props) => {
    const {
        open,
        onClose,
        language,
        taskId,
    } = props;

    /**
     * The current selected item
     *
     * selectedItem: { id: number; um: string; pricePerUnit: number; name: string }
     */
    const [selectedItem, setSelectedItem] = useState({});

    /**
     * The car inventory
     *
     * inventory: { id: number; um: string; pricePerUnit: number; name: string }[]
     */
    const [inventory, setInventory] = useState([]);
    const [quantity, setSelectedQuantity] = useState(1);

    /**
     * The selected items that will be saved
     *
     * saveItems: { id: number, quantity: number, name: string }[]
     */
    const [savedItems, setSavedItems] = useState([]);


    const saveItem = (id, quantity, name) => {
        const itemIndex = savedItems.findIndex(x => x.id === id);
        const tmp = [...savedItems];

        if (itemIndex !== -1) {
            tmp[itemIndex] = {quantity: tmp[itemIndex].quantity + quantity, id: id, name}
        } else {
            tmp.push({quantity, id, name});
        }

        setSavedItems(tmp);
    };

    const deleteItem = (id) => {
        const itemIndex = savedItems.findIndex(x => x.id === id);

        if (itemIndex !== -1) {
            const tmp = [...savedItems];
            tmp.splice(itemIndex, 1);
            setSavedItems(tmp);
        }
    }

    const fetchInventory = async () => {
        try {
            const res = await axios.get('/api/temp/inventory', getGetHeader());
            if (res.data && res.data.length > 0) {
                setInventory(res.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const saveProgress = async () => {
        try {
            await axios.post('/api/task/technical/progress',
                {
                    taskId,
                    data: savedItems
                },
                getPostHeader()
            );
            setSavedItems([]);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchInventory().then(() => console.log('inventory fetch'));
    }, []);

    return (
        <CustomModal
            open={open}
            fullWidth
            title={"Înregistrare progress"}
            content={
                <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                    <Grid item xs={8}>
                        <InputLabel id='loc' style={{marginBottom: '10px'}}>Componentă</InputLabel>
                        <Select
                            variant={"outlined"}
                            fullWidth
                            labelId='loc'
                            value={selectedItem}
                            onChange={(e) => {
                                setSelectedItem(e.target.value)
                            }}
                        >
                            {inventory.map(i => <MenuItem key={i.id} value={i}>{i.name}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel id='um' style={{marginBottom: '10px'}}>Cantitate</InputLabel>
                        <TextField
                            value={quantity}
                            onChange={e => setSelectedQuantity(e.target.value)}
                            variant={"outlined"}
                        />
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '20px'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={Object.keys(selectedItem).length === 0}
                            onClick={() => {
                                saveItem(selectedItem.id, quantity, selectedItem.name);
                                setSelectedItem({});
                                setSelectedQuantity(1);
                            }}
                        >
                            Adaugă
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: "20px", marginBottom: "20px"}}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: "10px"}}>
                        {savedItems.map((s, i) => <Chip key={`${s.name}_${i}`} style={{margin: "5px"}}
                                                        label={s.name + ' : ' + s.quantity}
                                                        onDelete={() => deleteItem(s.id)} color="primary"/>)}
                    </Grid>
                </Grid>
            }
            close={() => onClose()}
            execute={() => {
                saveProgress();
                onClose();
            }}
            language={language}
            validate={savedItems.length > 0}
        />
    )
};

export default ModalRegisterTaskProgress;